import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';
import SmoothScroll from 'smooth-scroll';
import Swiper from 'swiper';
import "./css/global.css";
import "./css/header.css";
import "./css/footer.css";
import "./css/home.css";
import "./css/inner-pages.css";

var scroll = new SmoothScroll('a[href*="#"]');

const bodyTag = document.body;
let header = document.querySelector('.header-container');
let computedStyle = getComputedStyle(header);
let headerBoxHeight = computedStyle.height;
const mobileMenu = document.getElementById("mobile-menu");
const mobileMenuOpen = document.getElementById("mobile-menu-open");
const mobileMenuClose = document.getElementById("mobile-menu-close");
const siteCopyright = document.getElementById('copyright-date');

mobileMenuOpen.addEventListener('click', e => {
  disableBodyScroll(mobileMenu);
});

mobileMenuClose.addEventListener('click', e => {
  enableBodyScroll(mobileMenu);
});

var today = new Date();
var yyyy = today.getFullYear();

today = yyyy;
siteCopyright.innerText = today;

function init() {
  // A demo: add an element to the document, then announce it
  const alertNode = document.createElement("div");
  const mainNode = document.querySelector("main");

  alertNode.setAttribute("role", "status");
  alertNode.setAttribute("aria-live", "polite");
  mainNode.appendChild(alertNode);

  // Wait some arbitrary time, then populate it
  setTimeout(() => {
    const successNode = document.createElement("p");
    // Let's verify that Vite is injecting environment variables
    // @see https://vitejs.dev/guide/env-and-mode.html#env-variables
    if (import.meta.env.DEV === true) {
      successNode.innerText = "Vite is serving the script correctly!";
    }
    if (import.meta.env.PROD === true) {
      successNode.innerText =
        "";
    }
    alertNode.appendChild(successNode);
  }, 400);
}

init();

let doc = document.documentElement;
let w = window;

let prevScroll = w.scrollY || doc.scrollTop;
let curScroll;
let direction = 0;
let prevDirection = 0;

var checkScroll = function() {
  if (curScroll == 0) {
    header.style.cssText = "transform: translateY(0px);";
  }
  /*
  ** Find the direction of scroll
  ** 0 - initial, 1 - up, 2 - down
  */
  curScroll = w.scrollY || doc.scrollTop;
  if (curScroll > prevScroll) { 
    //scrolled up
    direction = 2;
  }
  else if (curScroll < prevScroll) { 
    //scrolled down
    direction = 1;
  }

  if (direction !== prevDirection) {
    toggleHeader(direction, curScroll);
  }

  prevScroll = curScroll;
};

var toggleHeader = function(direction, curScroll) {
  
  if (direction === 2 && curScroll > 1) { 

    //replace 52 with the height of your header in px

    header.style.cssText = "transform: translateY(-106px);";
    prevDirection = direction;
  }
  else if (direction === 1) {
    header.style.cssText = "transform: translateY(0px)";
    prevDirection = direction;
  }
};

window.addEventListener('scroll', checkScroll);

let mobileLightLogo = document.getElementById('mobile-light-logo');
let mobileDarkLogo = document.getElementById('mobile-dark-logo');
let heroImg = document.getElementById('hero-img');
console.log(heroImg);
if (heroImg !== null) {
  mobileDarkLogo.style.display = "none";
} else {
  mobileLightLogo.style.display = "none";
}

const swiper = new Swiper('.swiper', {
  slidesPerView: 1,
  spaceBetween: 20,
  centeredSlides: true,
  centeredSlidesBounds: true,
  breakpoints: {
    576: {
      slidesPerView: 2,
    },
    992: {
      slidesPerView: 3,
    },
    1200: {
      slidesPerView: 4,
    }
  },
});